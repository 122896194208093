<template>
	<div
		v-dblclick="onDoubleClick"
		class="split-view-layout"
		:class="{ 'is-vertical': isVertical }"
		:style="layoutStyle"
		@mousedown="onMouseDown"
		@mousemove="onMouseMove"
		@mouseup="onMouseUp"
		@touchstart="onTouchStart"
		@touchmove="onTouchMove"
		@touchend="onTouchEnd"
	>
		<slot></slot>
	</div>
</template>

<script>
import { eventBus } from '@services/eventBus'

export default {
	provide() {
		return {
			$splitViewLayout: this,
		}
	},
	props: {
		resizable: Boolean,
		minBasis: { type: Number, default: 100 },
	},
	data() {
		return {
			isVertical: true,
			layoutStyle: {},
			views: {},
			secondaryViewWidth: null,
			secondaryViewHeight: null,
			tracking: null,
		}
	},
	computed: {
		primaryView() {
			return Object.values(this.views).find(v => v.isPrimary)
		},
		secondaryView() {
			return Object.values(this.views).find(v => v.isSecondary)
		},
		showSecondaryView() {
			return !!this.secondaryView && this.mq.medium
		},
		secondaryViewBasis() {
			const viewBasis = this.isVertical ? this.secondaryViewHeight : this.secondaryViewWidth
			if (!viewBasis) return null
			return typeof viewBasis === 'string' ? viewBasis : `${viewBasis}px`
		},
		isManual() {
			if (this.isVertical) return !!this.secondaryViewHeight
			return !!this.secondaryViewWidth
		},
	},
	watch: {
		isVertical(val) {
			if (this.showSecondaryView) {
				this.$emit('update:orientation', val)
				this.$nextTick(() => eventBus.post(eventBus.type.RESIZE))
			}
		},
	},
	methods: {
		onDoubleClick(e) {
			if (e.target && e.target.attributes['data-handle-id']) {
				this.secondaryViewWidth = null
				this.secondaryViewHeight = null
				eventBus.post(eventBus.type.RESIZE)
			}
		},
		onMouseDown(e) {
			this.startTracking(e.target, e.screenX, e.screenY)
		},
		onMouseMove(e) {
			this.track(e.screenX, e.screenY)
			if (this.tracking) e.preventDefault()
		},
		onMouseUp() {
			this.endTracking()
		},
		onTouchStart(e) {
			const { screenX, screenY } = e.touches[0]
			this.startTracking(e.target, screenX, screenY)
		},
		onTouchMove(e) {
			const { screenX, screenY } = e.touches[0]
			this.track(screenX, screenY)
			if (this.tracking) e.preventDefault()
		},
		onTouchEnd() {
			this.endTracking()
		},
		startTracking(target, screenX, screenY) {
			const handleId = target && target.attributes['data-handle-id']
			if (!handleId) return

			const id = handleId.value
			const view = this.views[id]
			this.tracking = {
				id,
				view,
				startX: screenX,
				startY: screenY,
				startWidth: view.$el.clientWidth,
				startHeight: view.$el.clientHeight,
			}
		},
		track(screenX, screenY) {
			if (!this.tracking) return
			const { startX, startY, startWidth, startHeight } = this.tracking
			if (this.isVertical) {
				const maxBasis = this.$el.clientHeight - this.minBasis
				const offset = startY - screenY
				this.secondaryViewHeight = Math.max(Math.min(startHeight + offset, maxBasis), this.minBasis)
			} else {
				const maxBasis = this.$el.clientWidth - this.minBasis
				const offset = startX - screenX
				this.secondaryViewWidth = Math.max(Math.min(startWidth + offset, maxBasis), this.minBasis)
			}
			eventBus.post(eventBus.type.RESIZE)
		},
		endTracking() {
			eventBus.post(eventBus.type.RESIZE)
			this.tracking = null
		},
	},
}
</script>

<style lang="scss" scoped>
.split-view-layout {
	display: flex;
	height: 100%;
	&.is-vertical {
		flex-direction: column;
	}
}
</style>
