<template>
	<div
		v-if="show"
		class="split-view"
		:class="{ 'is-vertical': $splitViewLayout.isVertical, 'is-secondary': isSecondary }"
		:style="containerStyle"
	>
		<!--Split View Header (Title, Close & Docking Buttons)-->
		<template v-if="isSecondary">
			<div class="split-view-handle-target" :data-handle-id="_uid">
				<div class="split-view-handle" :data-handle-id="_uid"></div>
			</div>
			<div class="split-view-header">
				<span class="split-view-title">{{ title }}</span>
				<slot name="header-buttons"></slot>
				<template v-for="({ dock, icon, iconStyle, isVertical }, i) in orientations">
					<button v-if="$splitViewLayout.isVertical !== isVertical" :key="i">
						<svg-icon
							:icon="icon"
							:title="$options.filters.capitalize(dock)"
							:style="iconStyle"
							@click.native="$splitViewLayout.isVertical = isVertical"
						/>
					</button>
				</template>
				<button v-if="closable" @click="$emit('close')">
					<svg-icon icon="close" />
				</button>
			</div>
		</template>
		<!--Main Content-->
		<div class="split-view-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['$splitViewLayout'],
	props: {
		title: String,
		isPrimary: Boolean,
		closable: { type: Boolean, default: true },
		maxHeightSelector: String,
	},
	data() {
		return {
			orientations: [
				{ dock: 'bottom', icon: 'dock-bottom', iconStyle: '', isVertical: true },
				{ dock: 'right', icon: 'dock-left', iconStyle: 'transform:rotate(180deg)', isVertical: false },
			],
		}
	},
	computed: {
		show() {
			return this.isPrimary || this.$splitViewLayout.showSecondaryView
		},
		isSecondary() {
			return !this.isPrimary
		},
		containerStyle() {
			const { isVertical, showSecondaryView, secondaryViewBasis, isManual } = this.$splitViewLayout
			if (this.isPrimary) {
				if (showSecondaryView) {
					if (isManual) return null
					const maxWidth = !isVertical ? '50%' : undefined
					const maxHeight = isVertical ? '50%' : undefined
					return {
						flexGrow: 0,
						flexShrink: 0,
						maxWidth,
						maxHeight,
					}
				}
				return null
			}

			if (secondaryViewBasis)
				return {
					flexShrink: 0,
					flexGrow: 0,
					flexBasis: secondaryViewBasis,
				}
			return null
		},
	},
	mounted() {
		const { views, isVertical } = this.$splitViewLayout
		this.$set(views, this._uid, this)
		this.$emit('open', { isVertical })
	},
	beforeDestroy() {
		this.$set(this.$splitViewLayout.views, this._uid, undefined)
		delete this.$splitViewLayout.views[this._uid]
	},
	methods: {
		closeView() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.split-view {
	--handle-target-thickness: 10px;
	--handle-thickness: 3px;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	height: 100%;
	.split-view-handle-target {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		.split-view-handle {
			background-color: var(--divider);
			opacity: 0.5;
			pointer-events: none;
		}
		&:hover {
			.split-view-handle {
				opacity: 1;
			}
		}
	}
	&.is-vertical {
		overflow-y: hidden;
		&.is-secondary {
			padding-top: var(--handle-target-thickness);
		}
		.split-view-handle-target {
			height: var(--handle-target-thickness);
			width: 100%;
			top: 0;
			cursor: ns-resize;
			.split-view-handle {
				height: var(--handle-thickness);
				width: 100%;
			}
		}
		.split-view-content {
			flex-grow: 1;
			overflow-y: hidden;
			height: 100%;
		}
	}
	&:not(.is-vertical) {
		overflow-x: hidden;
		&.is-secondary {
			padding-left: var(--handle-target-thickness);
		}
		.split-view-handle-target {
			width: var(--handle-target-thickness);
			height: 100%;
			left: 0;
			cursor: ew-resize;
			touch-action: manipulation;
			.split-view-handle {
				width: var(--handle-thickness);
				height: 100%;
			}
		}
		.split-view-content {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			overflow-x: hidden;
		}
	}
	.split-view-header {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		padding: 0 4px;
		color: var(--secondary-label);
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.025em;
		user-select: none;
		.split-view-title {
			flex-grow: 1;
			margin: 0 14px;
		}
		button {
			background: transparent;
			cursor: pointer;
			border: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 24px;
			.icon {
				margin: 0;
				font-size: 18px;
				color: var(--tertiary-label);
				&:hover {
					color: var(--primary-label);
				}
				&.is-selected {
					color: var(--checkbox-checked-bg);
				}
			}
		}
	}
}
</style>
