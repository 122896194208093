<template>
	<div class="table-container" data-cy="data-table">
    <div v-if="editMode" class="table-settings-buttons">
      <button class="btn btn-default" @click="addColumn">
        <svg-icon icon="plus" />
        Add Column
      </button>
      <button class="btn btn-default" title="Reset to Default"  @click="resetChanges">
        <svg-icon icon="undo" />
        Reset
      </button>
      <button class="btn btn-default"  @click="cancelChanges">
        <svg-icon icon="close" />
        Cancel
      </button>
      <button class="btn btn-default"  @click="saveChanges">
        <svg-icon icon="check" />
        Save Changes
      </button>
    </div>
		<table
      :key="componentKey"
			class="data-table"
			:class="{
				'table--striped': isStriped,
				'table--striped-report': isReport,
				'table--row-hover': isHoverable,
				'table--condensed': isCondensed,
				'table--full-width': isFullWidth,
			}"
			:style="isLoading && { opacity: '0.3', transition: 'opacity 0.1s 0.1s' }"
		>
			<slot>
				<!-- Table content -->
				<thead>
					<tr
            v-if="!editMode"
						:class="{
							asc: sort.isAscending && sort.isSorted,
							desc: !sort.isAscending && sort.isSorted,
						}"
					>
						<!-- Columns -->
            <th class="break-sm">
              <button
                v-if="!editMode"
                class="configure-menu"
                title="Configure Columns"
                @click.stop.prevent="enableEditMode"
              >
                <svg-icon icon="settings" class="table-settings-icon"/>
              </button>
            </th>
						<th
							v-for="(column, i) in filteredList"
              :key="i"
							:class="{
								active: sort.orderBy === column.sortName,
								sortable: column.isSortable,
							}"
							@click.prevent="sortSelected(column)"
						>
							{{ column.name }}
						</th>
					</tr>
          <tr
            v-if="editMode"
            :key="componentKey"
          >
            <!-- Columns -->
            <th>
              &nbsp;
            </th>
            <th
              v-for="(column, i) in filteredList"
              :key="`column-${componentKey}-${i}`"
              >
              <v-select
                v-if="column.name"
                v-model="filteredList[i]"
                :options="columnList"
                append-to-body
                :clearable="false"
                :filterable="false"
                :searchable="false"
                :label="column.name"
                :get-option-label="columnAc => columnAc.name"
                @option:selecting="selected(i)"
              >
                <template #option="col">
                  <span v-if="col.showInSelector" >{{ col.name }}</span>
                </template>
                <template #footer>
                  <div>
                    <ast-checkbox
                      :key="filteredList[i].Id"
                      v-model="filteredList[i].isVisible"
                      style="display: inline-flex !important;"
                      @input="hideColumn(i)"
                    >
                      Visible
                    </ast-checkbox>
                  </div>
                </template>
              </v-select>
            </th>
          </tr>
				</thead>
				<tbody>
					<template v-for="(row, rowIndex) in rows">
						<tr
							:key="`row-${rowIndex}`"
							:class="[
								expandedRow === row ? 'selected' : '',
								isExpanding && expandedRow === row ? 'expanding' : '',
								row.wasRemoved ? 'removed' : '',
								row.rowClass ? row.rowClass : '',
								editMode ? 'disabled' : '',
							]"
							@click.prevent="onRowClick(row)"
						>
							<!-- Row -->
							<slot name="row" :row="row"></slot>
						</tr>

						<!-- Expanded row -->
						<transition :key="`expanded-${rowIndex}`" name="expand">
							<tr v-if="$slots.expanded && expandedRow === row" ref="expandedRow" class="expanded">
								<td :colspan="columns.length">
									<slot name="expanded"></slot>
								</td>
							</tr>
						</transition>

						<!-- expand with two tr elements to prevent changing striping of remaining rows -->
						<tr v-if="$slots.expanded && expandedRow === row" :key="`striping-fix-${rowIndex}`" class="striping-fix">
							<td :colspan="columns.length"></td>
						</tr>
					</template>
				</tbody>
			</slot>
		</table>
	</div>
</template>

<script>
import AstCheckbox from "@components/Checkbox";

export default {
	name: 'CustomDataTable',
  components: {
    AstCheckbox,
  },
	props: {
    defaultTableHeaders: {
			type: Array,
			default: () => {
				return []
			},
		},
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },
		rows: {
			type: Array,
			default: () => {
				return []
			},
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		sort: {
			type: Object,
			default: () => {
				return {
					orderBy: '',
					isAscending: false,
					isSorted: false,
				}
			},
		},
		collapseOnListChange: {
			type: Boolean,
			default: true,
		},
		isExpanding: {
			type: Boolean,
			default: false,
		},
		expandedRow: {
			type: Object,
			default: null,
		},
		currentPage: {
			type: Number,
			default: 0,
		},
		resultsPerPage: {
			type: Number,
			default: 0,
		},
		isStriped: {
			type: Boolean,
			default: true,
		},
		isReport: {
			type: Boolean,
			default: false,
		},
		isHoverable: {
			type: Boolean,
			default: true,
		},
		isCondensed: {
			type: Boolean,
			default: false,
		},
		isFullWidth: {
			type: Boolean,
			default: true,
		},
    tableId: {
      type: String,
      default: ''
    }
	},
	data() {
		return {
			clickTimeout: null,
      editMode: false,
      headerColumnList: [],
      componentKey: 0,
      tempHeaders: [],
      renderComponent: true,
		}
	},
  computed: {
    filteredList: function() {
      let results = this.columns.filter((e, i) => {
        let timestamp = new Date()
        e.Id = timestamp.valueOf() + (e.columnName || '') + i
        return (e.isVisible || (!e.hasOwnProperty('name')))
      })
      return results
    },
    columnList: function() {
      return this.headerColumnList.filter(i => (i.hasOwnProperty('name') && i.showInSelector))
    }
  },
	watch: {
		rows() {
			if (this.collapseOnListChange) this.hideExpandedRow()
		},
		currentPage() {
			this.hideExpandedRow()
		},
    defaultTableHeaders() {
      this.updateHeaderList()
    },
	},
  created() {
    this.updateHeaderList()
  },
	methods: {
    updateHeaderList() {
      setTimeout(() => {
        this.headerColumnList = this.defaultTableHeaders.map(o => ({...o}))
      }, )
    },
    enableEditMode() {
      this.tempHeaders = this.columns.map(o => ({...o}))
      this.editMode = true
    },
    forceUpdate() {
      this.renderComponent = false
      this.componentKey += 1
      this.$nextTick(() => {
        this.renderComponent = true
        this.$forceUpdate()
      })
    },
    addColumn() {
      this.$nextTick(() => {
        let columnToAdd = Object.assign({}, this.columnList[0]);
        columnToAdd.isVisible = true;
        let index = this.columns.length - 1
        let temp = this.columns[index];
        this.$set(this.columns, index, columnToAdd)
        this.$set(this.columns, (index + 1), temp)
        this.forceUpdate()
      })
    },
    hideColumn(index){
      this.columns.splice(index, 1)
    },
    selected(index){
      setTimeout(() => {
        this.$set(this.columns, index, this.filteredList[index])
        this.forceUpdate()
      });
    },
    saveChanges() {
      let actionObject = {
        action: 'SAVE'
      }
      this.$emit('column-action', actionObject)
      this.editMode = false
    },
    resetChanges(){
      let actionObject = {
        action: 'RESET'
      }
      this.$emit('column-action', actionObject)
    },
    cancelChanges(){
      let actionObject = {
        action: 'CANCEL',
        value: this.tempHeaders
      }
      this.$emit('column-action', actionObject)
      this.editMode = false
    },
		hideExpandedRow() {
			this.setExpandedRow()
		},
		onRowClick(row, rowIndex) {
      const clear = () => {
        clearTimeout(this.clickTimeout)
        this.clickTimeout = null
      }
      if (this.clickTimeout) {
        // Double click
        clear()
        this.$emit('row-open', row)
      } else {
        // Single click
        const self = this
        this.clickTimeout = setTimeout(() => {
          clear()
          if (self.$slots.expanded) self.setExpandedRow(row)
          else self.$emit('row-open', row)
        }, 300)
      }
		},
		setExpandedRow(row) {
			if (this.expandedRow === row || row == null) {
				this.$emit('row-selected', undefined, this.expandedRow)
			} else {
				this.$emit('row-selected', row, this.expandedRow)
			}
		},
		sortSelected(item) {
			if (!item.isSortable) {
				return
			}

			this.hideExpandedRow()
			let orderBy = item.sortName
			let alreadyOrderedBy = orderBy === this.sort.orderBy
			let currentlyAsc = this.sort.isAscending

			// First sort click defaults
			let orderByName = orderBy
			let isAscending = true
			let isSorted = true

			// Set descending
			if (alreadyOrderedBy && currentlyAsc) isAscending = false
			else if (alreadyOrderedBy && !currentlyAsc) {
				// Remove sort
				orderByName = ''
				isSorted = false
				isAscending = false
			}

			this.$emit('sort-selected', {
				name: orderByName,
				isAscending: isAscending,
				isSorted: isSorted,
			})
		},
		getScrollParent(node) {
			if (node == null) return null
			const overflowY = node instanceof HTMLElement && window.getComputedStyle(node).overflowY
			const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
			if (isScrollable && node.scrollHeight > node.clientHeight) return node
			return this.getScrollParent(node.parentNode)
		},
		scrollSelectedRowIntoView(scrollToTop = false) {
			const el = this.$el.querySelector('tr.selected')
			const header = this.$el.querySelector('thead')
			const parent = this.getScrollParent(header)
			if (!el || !header || !parent) return

			// Don't do anything if element is fully visible in scroll area
			const bottomOverlap = el.offsetTop + el.clientHeight - (parent.scrollTop + parent.clientHeight)
			const topOverlap = parent.scrollTop + header.clientHeight - el.offsetTop
			if (topOverlap <= 0 && bottomOverlap <= 0) return

			// Scroll to selected row
			scrollToTop = scrollToTop || topOverlap > 0
			el.scrollIntoView(scrollToTop)

			// Offset from sticky header overlap if top aligned
			if (scrollToTop) parent.scrollTop -= header.clientHeight
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.table-container {
	min-height: 0.01%;
}
.action-button {
	color: var(--primary-label);
	display: inline-flex;
	width: 2em;
	height: 2em;
	background: transparent;
	border: 0;
	cursor: pointer;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.table-settings-buttons {
  float: right;
}
.table-settings-buttons button {
  margin-right: 4px;
}
</style>
