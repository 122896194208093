<template>
	<div class="page-wrapper">
		<split-view-layout ref="splitView" @update:orientation="toggleDrawerIfNeeded">
			<split-view title="List" is-primary>
				<div class="list-scroll">
					<search-and-filter
						:filter-presets="filterPresets"
						:filter="filter"
						:show-reset-button="true"
						@input="updateFilter($event)"
						@reset="updateFilter(filterPresets.ConsultantOpenAll)"
						@reset-current-page="currentPage = 0"
					>
						<template #tags>
							<router-link to="/teleconsultations/deep-search" tag="button" class="btn btn-default">
								<svg-icon icon="text-search" fixed />
								Keyword search
							</router-link>

							<filter-tag
								:value="filter.isComplete"
								clear-value="Any"
								@input="updateFilterComponent('isComplete', $event)"
							>
								{{ filter.isComplete }}
							</filter-tag>
							<filter-tag :value="filter.isDraft" clear-value="Any" @input="updateFilterComponent('isDraft', $event)">
								{{ filter.isDraft === 'Yes' ? 'Pending Review' : 'Not Pending Review' }}
							</filter-tag>
							<filter-tag
								:value="filter.modalityIds"
								:clear-value="[]"
								@input="updateFilterComponent('modalityIds', $event)"
							>
								{{ $store.getters.modalityNamesById(filter.modalityIds).join(' / ') }}
							</filter-tag>
							<filter-tag
								v-if="filter.requestType"
								:value="filter.requestType"
								clear-value="00000000-0000-0000-0000-000000000000"
								@input="updateFilterComponent('requestType', $event)"
							>
								{{ menu.getRequestTypeNameById(filter.requestType) }}
							</filter-tag>
							<filter-tag
								v-if="filter.consultantId"
								:value="filter.consultantId"
								clear-value=""
								@input="updateFilterComponent('consultantId', $event)"
							>
								{{ menu.getConsultantNameById(filter.consultantId) }}
							</filter-tag>
							<filter-tag
								v-if="filter.claimedByConsultantId"
								:value="filter.claimedByConsultantId"
								clear-value=""
								@input="updateFilterComponent('claimedByConsultantId', $event)"
							>
								<template v-if="filter.claimedByConsultantId === '00000000-0000-0000-0000-000000000001'">
									Unclaimed
								</template>
								<template v-else>
									Claimed by {{ menu.getGroupMemberNameById(filter.claimedByConsultantId) }}
								</template>
							</filter-tag>
							<filter-tag
								v-if="filter.clinicCode"
								:value="filter.clinicCode"
								clear-value=""
								@input="updateFilterComponent('clinicCode', $event)"
							>
								{{ menu.getClinicNameFromClinicCode(filter.clinicCode) }}
							</filter-tag>
							<filter-tag
								v-if="filter.region"
								:value="filter.region"
								clear-value=""
								@input="updateFilterComponent('region', $event)"
							>
								{{ menu.getRegionNameById(filter.region) }}
							</filter-tag>
							<filter-tag
								v-if="filter.startDate || filter.endDate"
								:value="{ startDate: filter.startDate, endDate: filter.endDate }"
								clear-value=""
								@input="clearFilterDateRange()"
							>
								Requested:
							</filter-tag>
							<filter-tag
								v-if="filter.responseStartDate || filter.responseEndDate"
								:value="{ startDate: filter.responseStartDate, endDate: filter.responseEndDate }"
								clear-value=""
								@input="clearFilterDateRange('response')"
							>
								Responded:
							</filter-tag>
							<filter-tag
								v-if="filter.studyStartDate || filter.studyEndDate"
								:value="{ startDate: filter.studyStartDate, endDate: filter.studyEndDate }"
								clear-value=""
								@input="clearFilterDateRange('study')"
							>
								Study Date:
							</filter-tag>

							<filter-tag
								v-if="filter.species"
								:value="{ species: filter.species }"
								clear-value=""
								@input="filter.species = null"
							>
								Species: {{ filter.species }}
							</filter-tag>

							<filter-tag
								v-if="filter.breed"
								:value="{ breed: filter.breed }"
								clear-value=""
								@input="filter.breed = null"
							>
								Breed: {{ filter.breed }}
							</filter-tag>

							<filter-tag
								v-if="filter.studyDescription"
								value="{ studyDescription: filter.studyDescription }"
								clear-value=""
								@input="filter.studyDescription = null"
							>
								{{ filter.studyDescription }}
							</filter-tag>

							<filter-tag
								v-if="filter.gender"
								:value="{ gender: filter.gender }"
								clear-value=""
								@input="filter.gender = null"
							>
								Gender: {{ filter.gender }}
							</filter-tag>

							<filter-tag
								v-if="filter.draftedById"
								:value="{ draftedById: filter.draftedById }"
								clear-value=""
								@input="filter.draftedById = null"
							>
								Drafted by: {{ menu.getGroupMemberNameById(filter.draftedById) }}
							</filter-tag>
						</template>
						<template #top>
							<!-- Status -->
							<div>
								<label>Status</label>
								<div>
									<button
										class="btn"
										:class="filter.isComplete === 'Open' ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isComplete', 'Open')"
									>
										Open
									</button>
									<button
										class="btn"
										:class="filter.isComplete === 'Complete' ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isComplete', 'Complete')"
									>
										Complete
									</button>
									<button
										class="btn"
										:class="!['Open', 'Complete'].includes(filter.isComplete) ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isComplete', 'Any')"
									>
										All
									</button>
								</div>
							</div>
							<!-- Pending Review -->
							<div v-if="isConsultantApprovalSupported">
								<label>Pending Review</label>
								<div>
									<button
										class="btn"
										:class="filter.isDraft === 'Yes' ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isDraft', 'Yes')"
									>
										Yes
									</button>
									<button
										class="btn"
										:class="filter.isDraft === 'No' ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isDraft', 'No')"
									>
										No
									</button>
									<button
										class="btn"
										:class="!['Yes', 'No'].includes(filter.isDraft) ? 'btn-success' : 'btn-default'"
										@click="updateFilterComponent('isDraft', 'Any')"
									>
										All
									</button>
								</div>
							</div>
						</template>

						<!-- Type (Service/Priority) -->
						<div v-if="showTeleconsultationScheduleFiltering">
							<label>Type</label>
							<div class="radio-group" style="font-size: 0.9em; letter-spacing: 0.05em">
								<ast-radio
									v-for="[requestType, guid] in requestTypes"
									:id="`request-type-${guid}`"
									:key="guid"
									:value="guid"
									:model="filter.requestType"
									@input="updateFilterComponent('requestType', $event)"
								>
									{{ requestType }}
								</ast-radio>
							</div>
						</div>
						<div v-if="!isConsultantUser">
							<label>Consultant</label>
							<v-select
								:value="filter.consultantId"
								label="name"
								:options="consultants"
								:reduce="o => o.id"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('consultantId', $event)"
							/>
						</div>
						<div v-if="isConsultantUser && groupMembers.length > 1">
							<label>Claimed By</label>
							<v-select
								:value="filter.claimedByConsultantId"
								label="name"
								:options="groupMembers"
								:reduce="o => o.id"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('claimedByConsultantId', $event)"
							/>
						</div>
						<div v-if="isConsultantUser && clientClinics.length">
							<label>Requested By</label>
							<v-select
								:value="filter.clinicCode"
								label="name"
								:options="clientClinics"
								:reduce="o => o.clinicCode"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('clinicCode', $event)"
							/>
						</div>
						<div v-if="isConsultantUser && showTeleconsultationScheduleFiltering">
							<label>Region</label>
							<v-select
								:value="filter.region"
								label="name"
								:options="regions"
								:reduce="o => o.id"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('region', $event)"
							/>
						</div>
						<!-- Request Date -->
						<date-range-picker
							label="Request Date"
							:date-ranges="dateRanges"
							:filter="filter"
							@input="updateFilter($event)"
						/>
						<!-- Response Date -->
						<date-range-picker
							start-date-key="responseStartDate"
							end-date-key="responseEndDate"
							label="Response Date"
							:date-ranges="dateRanges"
							:filter="filter"
							@input="updateFilter($event)"
						/>
						<!-- Study Date -->
						<date-range-picker
							start-date-key="studyStartDate"
							end-date-key="studyEndDate"
							label="Study Date"
							:date-ranges="dateRanges"
							:filter="filter"
							@input="updateFilter($event)"
						/>

						<div>
							<label>Species</label>
							<v-select
								:value="filter.species"
								label="name"
								:options="speciesList"
								:reduce="o => o.name"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('species', $event)"
							/>
						</div>

						<div>
							<label>Breed</label>
							<v-select
								:value="filter.breed"
								:options="breedList"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('breed', $event)"
							/>
						</div>

						<div>
							<label>Gender</label>
							<v-select
								:value="filter.gender"
								:options="genderList"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('gender', $event)"
							/>
						</div>

						<div>
							<label>Study Description</label>
							<input
								:value="filter.studyDescription"
								maxlength="200"
								type="text"
								class="input"
								@input="updateFilterComponent('studyDescription', $event.target.value)"
							/>
						</div>

						<div v-if="draftedByList.length">
							<label>Drafted By</label>
							<v-select
								:value="filter.draftedById"
								label="name"
								:options="draftedByList"
								:reduce="o => o.id"
								:searchable="false"
								:clear-search-on-select="false"
								@input="updateFilterComponent('draftedById', $event)"
							/>
						</div>
					</search-and-filter>

					<div v-scroll-nav="!mq.small" class="table-wrapper" :class="{ 'has-cards': !mq.medium }">
						<!-- Results are loaded, no results -->
						<section v-show="!isLoading && !list.length" class="no-results">
							<em> No results found </em>
						</section>

						<!--Table list-->
						<custom-data-table
							v-if="mq.medium"
							v-show="list.length || isLoading"
							ref="dataTable"
							:columns="columns"
              :default-table-headers="defaultColumns"
							:rows="list"
							:sort="listSort"
							:is-loading="isLoading"
							:expanded-row="expandedRow"
							:collapse-on-list-change="false"
							:is-report="reportColorSettings.hasDefinedColors"
							class="teleconsultation-table"
							@sort-selected="handleSortSelected"
							@row-selected="handleRowSelected"
							@row-open="handleRowOpen"
              @column-action="columnAction"
						>
							<template #row="{ row }">
								<td>
									<button class="action-button" @click.stop="openReportAndViewer(row)">
										<svg-icon icon="eye" fixed />
									</button>
								</td>
                <td
                  v-for="(column, i) in filteredList"
                  :key="i"
                  :class="column.classStr"
                  :style="column.styleStr"
                >
                  <span v-if="isColumnDataTypeText(column)">
                    {{ row[column.dataColumnName] }}
                  </span>
                  <span v-if="isColumnDataTypeDateTime(column)">
                    {{ row[column.dataColumnName] | localizeDate({ forceUTC: false }) }}
                  </span>
                  <span v-if="isColumnDataTypeContent(column)">
                    <span v-if="column.dataColumnName === 'waitTime'">
                      <template v-if="row.waitTime">
                        {{ row.waitTime | friendlyDuration }}
                      </template>
                      <template v-else>N/A</template>
                    </span>
                  </span>
                  <span v-if="column.dataColumnName === 'status'">
                    <div v-if="row.imagesArchived < row.imageCount" style="text-align: center">
                      <progress-bar :width="(row.imagesArchived / row.imageCount) * 100" style="min-width: 96px" />
                      <span style="font-size: 0.75em"> {{ row.imagesArchived }}/{{ row.imageCount }} </span>
                    </div>
                    <span v-else>{{ row.status }}</span>
                  </span>
                </td>
							</template>
							<template #expanded
								><div></div
							></template>
						</custom-data-table>

						<!--Card list-->
						<div v-if="!mq.medium" v-show="list.length" class="panel-list-container">
							<ast-card
								v-for="(item, index) in list"
								:key="index"
								class="teleconsultation-card"
								:show-header="false"
								:is-hoverable="true"
								:is-loading="isLoading"
								@click.native="handleRowOpen(item)"
							>
								<div>
									<button class="view-button" @click.stop="openReportAndViewer(item)">
										<svg-icon icon="eye" />
									</button>
									<!-- Consultant -->
									<div>
										<section style="margin: 0 0 15px 0">
											<div>
												<span class="label">
													<svg-icon icon="user-md" aria-hidden="true" />
													Consultant:
												</span>
												<span>{{ item.consultant }}</span>
											</div>

											<div v-if="item.draftedBy">
												<span class="label">
													<svg-icon icon="user-md" aria-hidden="true" />
													Drafted By:
												</span>
												<span>{{ item.draftedBy }}</span>
											</div>
										</section>
									</div>

									<div style="display: flex; flex-wrap: wrap">
										<!-- Request -->
										<div style="flex: 1; padding: 8px; min-width: 160px">
											<h4 style="margin-bottom: 4px">Request</h4>
											<ul>
												<li>
													<span class="label">Date:</span>
													<span>{{ item.requestDate | localizeDate }}</span>
												</li>
												<li>
													<span class="label">Study Date:</span>
													<span>{{ item.studyDate | localizeDate({ forceUTC: false }) }}</span>
												</li>
												<li>
													<span class="label">Client:</span>
													<span>{{ item.requestingDoctor }}</span>
												</li>
												<li>
													<span class="label">Clinic:</span>
													<span>{{ item.clinic }}</span>
												</li>
												<li>
													<span class="label">Modality:</span>
													<span>{{ item.modality }}</span>
												</li>
												<li>
													<span class="label">Images:</span>
													<span>{{ item.imageCount }}</span>
												</li>
											</ul>
										</div>

										<!-- Patient -->
										<div style="flex: 1; padding: 8px; min-width: 160px">
											<h4 style="margin-bottom: 4px">Patient</h4>
											<ul>
												<li>
													<span class="label">Id:</span>
													<span>{{ item.patientId }}</span>
												</li>
												<li>
													<span class="label">Name:</span>
													<span>{{ item.patientName }}</span>
												</li>
												<li>
													<span class="label">Owner:</span>
													<span>{{ item.ownerName }}</span>
												</li>
												<li>
													<span class="label">Species:</span>
													<span>{{ item.species || '-' }}</span>
												</li>
												<li>
													<span class="label">Age:</span>
													<span>{{ item.patientAge || '-' }}</span>
												</li>
											</ul>
										</div>
									</div>

									<!-- STATUS / TYPE -->
									<div>
										<div v-if="item.imagesArchived < item.imageCount" style="text-align: center">
											<progress-bar :width="(item.imagesArchived / item.imageCount) * 100" style="min-width: 96px" />
											<span style="font-size: 0.75em"> {{ item.imagesArchived }}/{{ item.imageCount }} </span>
										</div>
										<section v-else style="margin: 15px 0 0 0">
											<div>
												<span class="label"> Type: </span>
												<span>{{ item.type }}</span>
											</div>
											<div>
												<span class="label"> Status: </span>
												<span>{{ item.status }}</span>
											</div>
										</section>
									</div>
								</div>
							</ast-card>
						</div>
					</div>
					<ast-pagination
						v-show="list.length"
						:current-page="currentPage"
						:results-per-page="resultsPerPage"
						:results-length="list.length"
						:has-more-results="hasMoreResults"
						@set-results-per-page="resultsPerPage = $event"
						@set-current-page="currentPage = $event"
					/>
				</div>
			</split-view>
			<split-view v-if="selectedRow" :title="selectedRowTitle" @open="toggleDrawerIfNeeded" @close="onSplitViewClose">
				<template #header-buttons>
					<button title="Expand details" @click="handleRowOpen(selectedRow)">
						<svg-icon icon="external-link" size="16px" />
					</button>
				</template>
				<sale-submission v-if="selectedRow.isSaleSubmission" :id="selectedRow.teleconsultationId" ref="reportDetail" />
				<image-only-report
					v-else-if="selectedRow.isImageOnly"
					:id="selectedRow.teleconsultationId"
					ref="reportDetail"
				/>
				<teleconsultation-detail v-else :id="selectedRow.teleconsultationId" ref="reportDetail" />
			</split-view>
		</split-view-layout>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SplitViewLayout from '@/components/SplitViewLayout.vue'
import SplitView from '@components/SplitView.vue'
import AstRadio from '@components/Radio'
import CachedFilter from '@mixins/cachedFilter.js'
import MetaColumn from '@store/models/base/TableMetaColumn'
import consultations from '@services/consultationService'
import ListMixin from '@mixins/list.js'
import { menu } from '@services/menu'
import ProgressBar from '@components/ProgressBar.vue'
import TeleconsultationDetail from '@router/views/TeleconsultationDetail.vue'
import SaleSubmission from '@router/views/SaleSubmission.vue'
import ImageOnlyReport from '@router/views/ImageOnlyReport.vue'
import api from '@services/api'
import { eventBus } from '@services/eventBus'
import CustomDataTable from "@components/CustomDataTable"
import _ from "lodash/fp";
import GoogleAnalytics from '@services/analyticsService'

const defaultFilter = menu.extendDefaultFilter()

export default {
	name: 'TeleconsultationList',
	components: {
		SplitViewLayout,
		SplitView,
		TeleconsultationDetail,
		SaleSubmission,
		ImageOnlyReport,
		AstRadio,
		ProgressBar,
    CustomDataTable,
	},
	filters: {
		friendlyDuration(hours) {
			if (hours < 2 / 60) {
				return '1 minute'
			} else if (hours < 2) {
				return Math.floor(60 * hours) + ' minutes'
			} else if (hours < 72) {
				return Math.floor(hours) + ' hours'
			} else {
				return Math.floor(hours / 24) + ' days'
			}
		},
	},
	mixins: [CachedFilter, ListMixin],
	data() {
		return {
			draftedByList: [],
			genderList: ['Male', 'Male Castrated', 'Female', 'Female Spayed'],
			speciesList: [],
			breedList: [],
			menu,
			refreshInterval: undefined,
			filter: defaultFilter,
			selectedRow: null,
      userHeaderPrefs: [],
      defaultColumns:[],
      columns: [],
      dateRanges: ['Year', 'Week', 'Yesterday', 'Today'],
		}
	},
	computed: {
    filteredList: function() {
      return this.columns.filter(i => {
        return (i.isVisible && i.dataColumnName) || i.isVisible
      })
    },
		groupMembers() {
			const groupMembers = [
				{ id: '00000000-0000-0000-0000-000000000001', name: 'Unclaimed' },
				...this.$store.state.consultant.groupMembers,
			]
			if (this.filter.claimedByConsultantId && !groupMembers.some(c => c.id === this.filter.claimedByConsultantId)) {
				// add missing selected consultant to claimed-by dropdown
				const consultant = this.$store.state.static.consultants.find(c => c.id === this.filter.claimedByConsultantId)
				if (consultant) groupMembers.push(consultant)
			}
			return groupMembers
		},
		...mapState({
			isConsultantApprovalSupported: state => state.auth.claims.isConsultantApprovalSupported,
			activeClinicCode: state => state.auth.claims.activeClinicCode,
			isConsultantUser: state => state.auth.claims.isConsultantUser,
			isConsultantMember: state => state.auth.claims.isConsultantMember,
			userId: state => state.auth.claims.userId,
			consultants: state => state.static.consultants,
			clientClinics: state => state.consultant.clientClinics,
			regions: state => {
				const regions = Object.entries(state.consultant.regions).map(([name, id]) => ({ name, id }))
				regions.unshift({ name: 'My Regions', id: '00000000-0000-0000-0000-000000000000' })
				return regions
			},
			showTeleconsultationScheduleFiltering: state => state.static.permissions.showTeleconsultationScheduleFiltering,
			reportColorSettings: state => state.reportColorSettings,
      tableHeaderSettings: state => state.viewer.tableHeadersSettings.teleconsultationListHeader,
		}),
		requestTypes() {
			return this.menu.requestTypes
		},
		menuSettings() {
			return this.menu.menuSettings
		},
		selectedRowTitle() {
			if (this.selectedRow) {
				return `${this.selectedRow.patientName} - ${this.selectedRow.patientId}`
			}
			return 'Details'
		},
		expandedRow() {
			return this.list?.find(r => r.teleconsultationId === this.selectedRow?.teleconsultationId)
		},
	},
	watch: {
		'filter.species': {
			handler(species) {
				this.getBreedList()
			},
		},
		'menu.presetFilters': {
			handler() {
				this.filterPresets = menu.presetFilters
			},
			immediate: true,
		},
    tableHeaderSettings: {
      handler() {
        this.userHeaderPrefs = this.tableHeaderSettings.length > 0 ? this.tableHeaderSettings : this.defaultColumns
      }
    },
    userHeaderPrefs() {
      let temp = []
      this.userHeaderPrefs.forEach((x) => {
        if (x.isVisibleCondition) {
          x.isVisible = this.resolveConditions(x.isVisibleCondition)
        }
        if (x.showInSelectorCondition) {
          x.showInSelector = this.resolveConditions(x.showInSelectorCondition)
        }
        temp.push(x)
      })
      this.columns = temp.map(o => ({...o}))
    }
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'login' && !to.query['filter-preset'] && menu.topTeleconsultationMenuItem) {
			next(menu.topTeleconsultationMenuItem.to)
		} else {
			next()
		}
	},
	async created() {
		this.speciesList = await api.patient.getSpecies()
    setTimeout(() => {
      this.setDefaultHeader()
      this.userHeaderPrefs = this.tableHeaderSettings.length > 0 ? this.tableHeaderSettings : this.defaultColumns

      if (this.columns.length > 0 && this.columns[0].id === 0) {
        this.columns.shift()
      }
      let store = this.$store
      eventBus.on('login', () => {
        let list = store.state.static.consultantsForAdmin
        this.draftedByList = []
        list.forEach(c => {
          c.groupsMembers.forEach(m => {
            if (m.type === 2 || m.type === 3) {
              this.draftedByList.push(list.find(d => d.id === m.id))
            }
          })
        })
      })

      this.refreshInterval = setInterval(this.refreshList, 30000, true, true)
    }, 10)
	},
	async mounted() {


	},
	beforeDestroy() {
		clearInterval(this.refreshInterval)
	},
	methods: {
		...mapActions(['openStudy']),
		async getBreedList() {
			let s = this.speciesList.find(s => s.name === this.filter.species)
			if (!s) {
				this.breedList = []
				return
			}

			const list = await api.patient.getBreeds(s.id)
			this.breedList = [...new Set(list.map(breed => breed.name))]
		},
		refreshFilterPresets() {
			menu.refreshFilterPresets()
		},
		async fetchList() {
			const data = await consultations.getList({
				params: {
					...this.filter,
					page: this.currentPage || 0,
					results: this.resultsPerPage || 10,
					queryTerm: this.filter.term || '',
					orderby: this.filter.order.by,
					isOrderAscending: this.filter.order.isAscending === true,
				},
			})
			if (data) {
				const _self = this
				this.list = data.results.map(item => {
					let rowClass = item.type.toLowerCase() === 'normal' ? 'local' : item.type.toLowerCase()
					if (_self.reportColorSettings.old.bg || _self.reportColorSettings.old.border) {
						const { threshold } = _self.reportColorSettings.old
						const { waitTime } = item
						const waitTimeTranslate = (hours, oldTime) => {
							let hoursVal = hours

							if (hours < 2 / 60) {
								hoursVal = 1
								if (oldTime >= 1) return rowClass
							} else if (hours < 2) {
								hoursVal = Math.floor(60 * hours) // minutes
								if (hoursVal > 1 && oldTime === 1) return 'old'
							} else if (hours <= 168 || hours >= 168) {
								hoursVal = Math.floor(hours) // hours
								if (hoursVal >= oldTime) return 'old'
							}

							return rowClass
						}

						rowClass = waitTimeTranslate(waitTime, threshold)
					}
					return {
						...item,
						// rowClass: rowClass, // TODO: We will move this back until Thick Client syncronizes correctly the color
						rowClass: '',
					}
				})
				this.hasMoreResults = data.hasMoreResults
			}
		},
		handleRowSelected(row, prevRow) {
			const prevRowExistsInList = this.list?.find(r => r.teleconsultationId === prevRow?.teleconsultationId)
			// Clear split view row if previous row was re-selected
			if (!row && prevRowExistsInList) {
				this.selectedRow = null
				// Otherwise just assign the selected row
			} else if (row) {
				this.selectedRow = row
				// Split view appearing could move selected row out of visible scroll area
				this.$nextTick(async () => {
					this.$refs.dataTable.scrollSelectedRowIntoView()
				})
			}
		},
		async handleRowOpen(row, callback) {
			this.isLoading = true // show loading spinner while teleconsultation detail loads
			const doneLoading = () => {
				this.isLoading = false
				if (callback) callback()
			}
			this.$router.push(
				{
					name: row.isSaleSubmission ? 'sale-submission' : row.isImageOnly ? 'image-only-report' : 'teleconsultation',
					params: { id: row.teleconsultationId },
				},
				doneLoading, // hide loading spinner after routing completes or is aborted
				doneLoading
			)
		},
		async openReportAndViewer(row) {
			this.handleRowOpen(row, () => {
				this.openStudy({
					clinicCode: this.activeClinicCode,
					reportId: row.requestReportId,
					modality: row.modality,
				})
			})
			try {
				GoogleAnalytics.sendGAEvent('Teleconsultation', {
					event_category: 'Launch',
					event_label: 'Teleconsultation Viewer'
				})
			}
			catch (err) {
				console.log(err)
			}
		},
		onSplitViewClose() {
			this.selectedRow = null
			if (this.$refs.dataTable) this.$refs.dataTable.hideExpandedRow()
			this.$nextTick(() => this.toggleDrawerIfNeeded())
		},
		toggleDrawerIfNeeded() {
			const { isVertical, secondaryView } = this.$refs.splitView
			const drawerIsOpen = this.$store.state.ui.isPersistentDrawerOpen
			let action = ''

			// Hide persistent drawer if constrained in horizontal orientation
			// ...or reopen if it was closed and it shouldn't be any more
			if (secondaryView) {
				if (isVertical) {
					if (this.didHide) action = 'open'
				} else if (!this.mq.xLarge) {
					action = 'close'
				}
			} else {
				if (this.didHide) {
					action = 'open'
				}
			}
			if ((action === 'open' && !drawerIsOpen) || (action === 'close' && drawerIsOpen)) {
				this.$store.commit('TOGGLE_PERSISTENT_DRAWER')
				this.didHide = drawerIsOpen
			}
		},
    isColumnDataTypeText: function(column) {
      return column.dataColumnType === 'text'
    },
    isColumnDataTypeDateTime: function(column) {
      return column.dataColumnType === 'dateTime'
    },
    isColumnDataTypeContent: function(column) {
      return column.dataColumnType === 'content'
    },
    async saveUserPreferences(){
      let payload = {
        tableHeaders: this.columns
      }
      try {
        await api.user.setUserSetting('ViewerSettings', 'TeleconsultationListHeader', JSON.stringify(payload))
      } finally {
      }
    },
    revertToDefaultTableHeader(){
      this.userHeaderPrefs = this.defaultColumns.map(o => ({...o}))
    },
    abortChanges(previousValue){
      this.userHeaderPrefs = previousValue.map(o => ({...o}))
    },
    columnAction(actionObject) {
      switch (actionObject.action){
        case 'RESET':
          this.revertToDefaultTableHeader()
          break
        case 'CANCEL':
          this.abortChanges(actionObject.value)
          break
        case 'SAVE':
          this.saveUserPreferences()
          break
      }
    },
    setDefaultHeader() {
      this.defaultColumns = [
        MetaColumn({
          id: 1,
          name: 'Type',
          dataColumnName: 'type',
          classStr: '',
          styleStr: 'font-size: 0.8em',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id: 2,
          name: 'Request Date',
          columnName: 'requestDate',
          isSortable: true,
          classStr: 'break-md',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'requestDate',
          dataColumnType: 'dateTime'
        }),
        MetaColumn({
          id: 3,
          name: 'Study Date',
          sortName: 'StudyDate',
          classStr: 'break-md',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'studyDate',
          dataColumnType: 'dateTime'
        }),
        MetaColumn({
          id: 4,
          name:'Consultant',
          classStr: '',
          styleStr: '',
          dataColumnName: 'consultant',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 5,
          name: 'Client',
          sortName: 'RequestingDoctor',
          classStr: 'break-md',
          styleStr: '',
          dataColumnName: 'requestingDoctor',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 6,
          name: 'Drafted By',
          dataColumnName: 'draftedBy',
          dataColumnType: 'text',
          isVisibleCondition: 'isConsultantApprovalSupported',
          showInSelectorCondition: 'isConsultantApprovalSupported'
        }),
        MetaColumn({
          id: 7,
          name: 'Clinic',
          classStr: '',
          styleStr: '',
          dataColumnName: 'clinic',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 8,
          name: 'Patient Id',
          classStr: 'break-sm',
          styleStr: '',
          dataColumnName: 'patientId',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 9,
          name: 'Patient',
          sortName: 'PatientName',
          classStr: 'break-sm',
          styleStr: '',
          dataColumnName: 'patientName',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 10,
          name: 'Owner',
          sortName: 'OwnerName',
          classStr: 'break-sm',
          styleStr: '',
          dataColumnName: 'ownerName',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 11,
          name: 'Species',
          classStr: 'break-sm',
          styleStr: '',
          dataColumnName: 'species',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 12,
          name: 'Age',
          isSortable: false,
          classStr: 'break-sm',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'patientAge',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 13,
          name: 'Modality',
          columnName: 'modality',
          sortName: 'ModalityId',
          classStr: 'break-sm',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'modality',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 14,
          name: 'Images',
          sortName: 'ImageCount',
          isSortable: false,
          classStr: 'break-sm',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'imageCount',
          dataColumnType: 'text'
        }),
        MetaColumn({
          id: 15,
          name: 'Wait Time',
          sortName: 'WaitTime',
          classStr: 'break-sm',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'waitTime',
          dataColumnType: 'content',
        }),
        MetaColumn({
          id: 16,
          name: 'Status',
          sortName: 'Status',
          classStr: 'break-sm',
          styleStr: 'font-size: 0.8em',
          dataColumnName: 'status',
          dataColumnType: 'content'
        }),
      ]
    },
    resolveConditions(condition) {
      switch (condition) {
        case 'isConsultantApprovalSupported' :
          return this.isConsultantApprovalSupported
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.teleconsultation-table table td {
	font-size: 0.9em;
}

.no-requests-found {
	color: var(--secondary-label);
}

.teleconsultation-card {
	.view-button {
		display: flex;
		float: right;
		background: var(--button-default-bg);
		color: var(--button-default-color);
		width: 48px;
		height: 48px;
		min-width: 48px;
		height: 48px;
		border: 0;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			background: var(--button-default-bg-hover);
			color: var(--button-default-color-hover);
		}
	}

	.label {
		font-size: 0.9em;
		padding-right: 8px;
	}

	ul {
		list-style-type: none;
	}
}
</style>
