// ================
// CachedFilter
// ================
// Caches the most recently used filter for the component when its value
// updates. Restores that filter in the created hook for the component.
// A less generic version of: https://github.com/coffee-driven-dev/v-cache
//
// Requirements:
// ----------------
// - Global $route
// - Component `filter` data
export default {
	computed: {
		cacheKey() {
			return `temp:${this.$route.name}:filter`
		},
	},
	watch: {
		filter: {
			handler(value) {
				const nullOrEmpty = !value || value === {}
				if (!nullOrEmpty) {
					sessionStorage.setItem(this.cacheKey, JSON.stringify(value))
				}
			},
			deep: true,
		},
	},
	async mounted() {
		const missingFilter = this.filter === null || this.filter === undefined
		if (missingFilter) {
			console.warn('cachedFilter mixin used, but component has no `filter` property')
			return
		}

		if (this.$route.query['filter-preset']) return

		// We check that properties match so we're not forcing an old/outdated
		// schema for visiting users between major versions
		const cachedFilter = JSON.parse(sessionStorage.getItem(this.cacheKey))
		if (cachedFilter && hasSameProperties(this.filter, cachedFilter)) {
			this.filter = Object.assign({}, this.filter, cachedFilter)
		}
	},
}

/**
 * Compares two object's top level properties. Returns true
 * if obj2 contains all of obj1's properties
 *
 * @param {*} obj1
 * @param {*} obj2
 * @returns
 */
function hasSameProperties(obj1, obj2) {
	return Object.keys(obj1).every(function(prop) {
		return obj2.hasOwnProperty(prop)
	})
}
