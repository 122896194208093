import { render, staticRenderFns } from "./SplitView.vue?vue&type=template&id=e41f3c46&scoped=true"
import script from "./SplitView.vue?vue&type=script&lang=js"
export * from "./SplitView.vue?vue&type=script&lang=js"
import style0 from "./SplitView.vue?vue&type=style&index=0&id=e41f3c46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e41f3c46",
  null
  
)

export default component.exports